@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import 'buttons';
@import "forms";
@import "sidenav";
@import "header";
@import "loader";

html {
  font-size: 32px;
}

@media (min-width: 1024px) {
  html {
    font-size: 14px
  }
}

h1 {
  @apply text-xl font-semibold;
}

h2 {
  @apply text-lg font-semibold;
}

.border-spacing-0 {
  border-spacing: 0;
}

.overflow-anchor-none {
  overflow-anchor: none;
}

.overflow-anchor {
  overflow-anchor: auto;
}
