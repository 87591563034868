.header {
  @apply flex justify-between items-center p-4;
}

.header h1 {
  @apply text-regular-blue font-bold text-3xl;
}

.header .actions {
  @apply flex flex-row gap-x-2;
}

.breadcrumbs {
  @apply p-4 font-bold flex flex-row;
}
