.form-group {
	@apply my-2 flex flex-col w-full;
}

.form-group label {
	@apply block text-xs font-semibold text-gray-600 my-1;
}

.form-group input[type="text"], .form-group input[type="number"], .form-group input[type="email"],
.form-group input[type="password"], .form-group textarea, .form-group input[type='date'] {
	@apply block w-full p-3 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded;
}

.form-group select {
	@apply block w-full p-3 text-gray-700 focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded;
}

.form-group input[type="file"] {
	@apply m-0;
}

p.help-block {
	@apply text-sm mx-2;
}

.has-error span.help-block {
	@apply text-sm mx-2 text-red-500;
}
