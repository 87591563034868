.btn--bare {
	@apply border rounded-md px-2 py-1 lg:px-4 lg:py-2 transition duration-500 select-none;
}

.btn {
	@apply text-white btn--bare;
}

.btn.btn-primary {
	@apply border-regular-blue bg-regular-blue hover:bg-hover-blue;
}

.btn.btn-outline {
	@apply transition duration-500 border-regular-blue bg-white hover:bg-regular-blue text-regular-blue hover:text-white;
}

.btn.btn-danger {
	@apply border-danger-red bg-danger-red hover:bg-hover-danger-red;
}

.btn.btn-danger.btn-outline {
	@apply transition duration-500 border-danger-red bg-white hover:bg-hover-danger-red text-danger-red hover:text-white;
}

.btn.btn-disabled {
	@apply border-gray-400 bg-gray-400;
}

.btn.btn-orange {
	@apply font-semibold border-regular-orange text-white bg-regular-orange hover:text-regular-orange hover:bg-white;
}

.btn.btn-green {
	@apply font-semibold border-regular-green text-white bg-regular-green hover:text-regular-green hover:bg-white;
}

.btn.btn-orange--outline {
	@apply font-semibold border-regular-orange text-regular-orange hover:text-white hover:bg-regular-orange;
}
