.sidenav {
  @apply flex bg-gray-100 lg:bg-white w-full h-16;
}

.sidenav nav {
  @apply flex flex-row w-full justify-between px-8 py-4 lg:p-0;
}

.sidenav nav a {
  @apply flex flex-row items-center h-8 lg:h-auto text-gray-600 hover:bg-gray-100 truncate lg:p-4;
}

.sidenav nav a svg {
  @apply h-8 lg:h-6 lg:w-6 lg:hidden;
}

.sidenav nav a.show-svg svg {
  @apply lg:flex; /* Adjusts SVG to be visible at large screens for specific link */
}
